/* Fonts */
@font-face {
  font-family: 'Copernicus';
  src: url('../fonts/copernicus-book.eot'); /* IE9 Compat Modes */
  src: url('../fonts/copernicus-book.otf') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/copernicus-book.woff') format('woff2'), /* Super Modern Browsers */
       url('../fonts/copernicus-book.woff') format('woff'), /* Pretty Modern Browsers */
       url('../fonts/copernicus-book.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('../fonts/copernicus-book.svg') format('svg'); /* Legacy iOS */
}

/* Reset */
h1, h2, a { font-family: 'Copernicus', Garamond, serif; }
p a { font-family: 'Poppins', Helvetica, sans-serif; }
h1 { font-size: 4.2rem; letter-spacing: -.188rem; }
h2 { font-size: 3.8rem; letter-spacing: -.125rem; padding: 0; margin: 0; }
h3 { font-size: 2.2rem; padding: 0 0 .625rem 0; }
h4 { font-size: 1.8rem; padding: .625rem 0 0 0; }
h5 { font-size: 1.2rem; }
h1, h2, h3, h4, h5 { font-weight: 400; color: #2a4a6c; }
p { font-size: 1.125rem; }
p { color: #555; }
a { color: #2a4a6c; letter-spacing: -0.031rem; }

section {
  position: relative;
  padding: 5rem;
}

section .cta {
  padding: 2rem 0 0;
}

.banner {
  padding: 0;
}
.banner .row {
  padding: 0;
  margin: 0;
}
.banner h1 {
  padding: 12.5rem 0 0;
}
.banner p {
  padding: .625rem 0;
}
.banner .cta { padding: .625rem 0 28rem; }
.banner .row .rug {
  width: 580px;
  position: absolute;
  bottom: 3rem;
  left: 0;
  right: 0;
  margin: 0 auto;
}
.banner .plants {
  position: absolute;
  bottom: 4rem;
}
.banner .plant-left {
  left: 0;
}
.banner .plant-right {
  right: 0;
}

.btn-primary {
  background-color: #3a6a7f !important;
  color: #fff !important;
}
.content {
  padding: 0;
}
.content section:nth-child(odd) {
  background: linear-gradient(#E6F1FF, #FEF8F1);
}
.content section:nth-child(even) {
  background-color: #FFF;
}
.content .row img {
  max-width: 100%;
}
.content .carousel .carousel-caption {
  position: static;
}
.content .carousel img  {
  margin: 0 auto;
}
.content .carousel-inner {
  padding-bottom: 4rem;
}
.content .carousel .carousel-indicators {
  margin-bottom: 0;
}
.content .carousel .carousel-indicators li {
  background-color: #2a4a6c;
  border: 0;
  border-radius: 14px;
  width: 14px; height: 14px;
  margin: 10px;
}
.content .carousel-control-next {
  background: linear-gradient(-90deg, #fff, 92%, transparent);
}
.content .carousel-control-prev {
  background: linear-gradient(90deg, #fff, 92%, transparent);
}
.content .carousel-control-next, .content .carousel-control-prev {
    opacity: 1;
}
.content .carousel-control-next-icon, .content .carousel-control-prev-icon {
  opacity: .5;
  filter: invert(100%);
}

.small-icons .row img {
  max-width: 70%;
}

/* Navbar */
.navbar {
  background: #13113e;
  box-shadow: none;
  transition: boxshadow .2s ease-in-out, background-color .2s ease-in-out;
}
.navbar a { font-size: 130%; }
.navbar .navbar-brand {
  font-size: 200%;
  font-family: 'Poppins', Helvetica, sans-serif;
  color: #0c4a72;
}
.navbar.active {
  -webkit-box-shadow: 0 0 2rem 0 rgb(41 48 66 / 10%);
  -moz-box-shadow: 0 0 2rem 0 rgb(41 48 66 / 10%);
  box-shadow: 0 0 2rem 0 rgb(41 48 66 / 10%);
}
.navbar.active .navbar-brand {
  color: #fff !important;
}

.navbar .nav-item .nav-link {
  color: #204a6f;
  padding: 1rem;
}

/* Side Menu */
.sidemenu {}
.sidemenu .toggle {
  left: 20px;
  z-index: 2000;
}

.sidemenu .toggle {
  width: 24px;
  height: 15px;
  position: relative;
  padding: 10px;
  transform: rotate(0deg);
  transition: .5s ease-in-out;
  cursor: pointer;
}
.sidemenu .toggle span {
  display: block;
  position: absolute;
  height: 3px;
  width: 24px;
  background: #4a5056;
  border-radius: 1px;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: .25s ease-in-out;
}
.sidemenu .toggle span:nth-child(1) {
  top: 0px;
  width: 85%;
}

.sidemenu .toggle span:nth-child(2) {
  top: 8px;
}

.sidemenu .toggle span:nth-child(3) {
  top: 16px;
  width: 65%;
}

.sidemenu .toggle.open span:nth-child(1) {
  top: 8px;
  width: 28px;
  height: 4px;
  transform: rotate(135deg);
}

.sidemenu .toggle.open span:nth-child(2) {
  opacity: 0;
  left: -60px;
}

.sidemenu .toggle.open span:nth-child(3) {
  top: 8px;
  width: 28px;
  height: 4px;
  transform: rotate(-135deg);
}

.sidemenu-main {
  position: absolute;
  z-index: 1000;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
  pointer-events: none;
  transition: opacity .2s ease-in-out, visibility .2s ease-in-out;
}
.sidemenu-open {
  overflow-y: hidden;
}
.sidemenu-open .sidemenu-main {
  visibility: visible;
  opacity: 1;
  pointer-events: auto;
}
.sidemenu-left {
  z-index: 1000;
  position: relative;
  height: 100vh;
  width: 100%;
  max-width: 600px;
  padding: 0 5px;
  background: linear-gradient(#EBEDFD, #FDFCF3 70%, #B5DAFF);
  overflow-y: scroll;
}
.sidemenu-left ul {
  list-style: none;
  padding-top: 6vh;
  margin-top: 1rem;
  padding-bottom: 3vh;
}

.sidemenu-left ul li {
  padding: 5px;
  margin: 0;
}
.sidemenu-left ul li h3 {
  padding: 0;
  margin: 0;
}
.sidemenu-left ul li h3 a {
  text-decoration: none;
}
.sidemenu-left .small-links {
  padding-top: 3vh;
}
.sidemenu-left .cta {
  margin: 1.4rem;
  padding: 1.4rem 2.8rem;
}
.sidemenu-left .sidemenu-image {
  position: absolute; right: -50%;
  top: 10%;
}
.sidemenu-right {
  position: absolute;
  width: 100%;
  height: 100vh;
  right: 0;
  top: 0;
  background: rgba(0,0,0,0.5);
  z-index: 900;
}
.sidemenu-right .sidemenu-overlay {
  background: rgba(0,0,0,0.5);
}
.sidemenu footer {
}
.sidemenu footer .notice {
  padding: 40px;
}
.sidemenu footer .notice p {
  font-size: 110%;
  color: #666;
}
.sidemenu footer img {
  width: 450px;
  margin-left: -100px;
  z-index: -1;
}
/* ========= */
/* Sections */
/* ========= */

/* How it Works - Section showing side-by-side images, titles, and captions */
.how-it-works {
}
.how-it-works img {
  padding: 1rem 0 0;
}

/* Features - section showing one big image, next to a features list */
.features ul {
  list-style: none;
  padding: 2.5rem 0 0;
  }
.features ul h3 {
  font-weight: 400;
  font-size: 3.2rem;
  padding: 0rem;
}

/* ========= */
/* Resources */
/* ========= */

/* Buttons */
.btn {
  padding: 1rem 1.8rem;
  border-radius: 3rem;
  background-color: #f2f2f2;
  font-size: 1.2rem;
  color: #333;
  border: 1px solid #ccc;
}

.btn-primary {
  background-color: #3a6a7f;
  color: #fff;
}

.btn .fas {
  font-size: .6rem;
  padding-left: .4rem;
  position: relative;
  bottom: .1rem;
}

/* Circle stats */
.circle-stats {
}
.circle-stats .circle {
  background-color: #4a8a9f;
  border: 10px solid #3a6a7f;
  border-radius: 50%;
  height: 15rem;
  width: 15rem;
  color: #fff;
  text-align: center;
  padding: 4.2rem 0;
  font-size: 3.7rem;
  margin: 1.5rem auto;
  color: #fff;
  font-weight: bold;
}

.circle-stats p {
}
.circle-stats .circle.ti-1 {
  text-indent: 0.4rem;
}
.circle-stats .circle.ti-2 {
  text-indent: 0.8rem;
}

.testimonials {
}
.testimonials i {
  font-size: 500%;
  padding: 0 0 20px;
}
.testimonials .card {
  background-color: #f2f2f2;
  width: 60%; padding: 3rem 3rem; border-radius: 20px; margin: 0 auto
}
.testimonials .card .card-header {
  padding-top: 2rem;
  background-color: #49788d;
}
.testimonials .card .card-header i, .testimonials .card .card-header h3, .testimonials .card .card-header p {
  color: #fff;
}

.testimonials .card h3 {
  padding-bottom: 5px;
}
.testimonials .card .card-header .star-review i {
  padding: .5rem 0 1.4rem 0;
  font-size: 1.6rem;
  color: gold;
}

/* Sitewide footer */
.site-footer {
  position: relative;
  padding: 5rem;
  /* background-color: #123658; */
  background-color: #060630;
  text-align: left;
}

.site-footer .container p span, .site-footer .container a {
  opacity: 0.6;
}

.site-footer .container a:hover {
  opacity: 1;
  transition: opacity .2s ease-in-out;
}

.site-footer h4, .site-footer p, .site-footer a, .site-footer a:link, .site-footer a:visited, .site-footer a:hover, .site-footer a:active {
  color: #fff;
}
.site-footer h4 {
}
.site-footer p, .site-footer a {
  font-size: 1.2rem !important;
}

.site-footer .list-inline {
  padding-bottom: 2rem;
}

.site-footer .copyright-wrapper {
  padding-top: 3rem;
  margin-bottom: -4rem;
}
.site-footer .copyright-wrapper hr {
  border-top: 1px solid #999;
}
.site-footer .copyright-wrapper p {
  padding: 1rem 0 0;
  opacity: 0.5;
}

/* OHP Customizations */
.ohp.night {
  background: #13113e !important;
}
.ohp.night .navbar a {
  color: #fff;
}

.ohp.night .banner h1, .ohp.night .banner span, .ohp.night .banner a {
  color: #fff !important;
}

.ohp.night .banner span, .ohp.night .banner a {
  opacity: 0.7;
}

.ohp.night .banner a:hover {
  opacity: 1;
  transition: opacity .2s ease-in-out;
}


.ohp.night .banner a:hover {

}

.ohp .banner {
  position: relative;
  min-height: 100vh;
  min-height: 800px;
  background: #13113e !important;
}
.ohp .banner .row {
  min-height: 100vh;
  min-height: 800px;
}

.ohp .banner p {
  font-size: 140%;
}

.ohp .banner .cta {
  padding-bottom: 0rem;
}

.ohp .banner .world_wrapper {
}

.ohp .banner .world_wrapper img {
}

.ohp .btn {
  font-size: 1.5rem;
  padding: 1rem 2.5rem;
}

.ohp .features ul {
  padding-top: 4.2rem;
}
.ohp .features ul h3 {
  font-size: 3rem;
}

.ohp .features .img-wrap {
  text-align: right;
}

.ohp .features img {
  padding-top: 1.3rem;
  width: 25rem;
  max-width: 90%;
}

.ohp .sidemenu footer img {
  right: auto;
  left: -80px;
  bottom: 0;
}

.ohp .site-footer p span, .ohp .site-footer p a {
  font-size: 1.2rem !important;
  color: #fff;
  /* opacity: 0.5; */
}

.ohp .site-footer:hover p span, .ohp .site-footer:hover p a {
  /* opacity: .75; */
}
.ohp .site-footer p {
}

.ohp .site-footer p a {
  text-decoration: underline;
}

@media (max-width: 576px) {
  .ohp .banner p { font-size: 1.4rem; }
  section { padding: 5rem 1.5rem; }

  .ohp .features .img-wrap {
    text-align: center;
  }
}



/* ================= */
/* Media Breakpoints */
/* ================= */

@media (min-width: 1200px) {
  html,body { font-size: 1rem; }
}

@media (max-width: 1200px) {
  html,body { font-size: 1rem; }
}
@media (max-width: 992px) {
  html,body { font-size: 1rem; }
}
@media (max-width: 768px) {
  html,body { font-size: .9rem; }
}
@media (max-width: 576px) {
  html,body { font-size: .85rem; }
  .site-footer {
    padding: 5rem 1.5rem;
  }
}

@media (max-width: 767.98px) {
    .main {
        overflow-x: hidden;
    }
    .banner h1 {
      padding-top: 10rem;
    }
}
